/*
 * @Author: Gang Jiang 
 * @Date: 2024-11-25 15:26:36 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-03-03 09:45:31
 */
<template>
  <div class="publisher">
    <div class="left">
      <left-menu :menus="menu" :bottomEntries="bottomEntries" />
    </div>
    <div class="right">
      <top-bar>
        <template #title>
          <p style="margin: 0 10px">Welcome to ArtemisAds</p>
        </template>
        <template #status v-if="store.userInfo.reviewStatus === 'Pending'">
          <a-tag class="cus-tag" color="warning" style=""
            >Account Pending Approval</a-tag
          >
        </template>
      </top-bar>
      <div class="content">
        <div class="min-height">
          <router-view />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script setup lang="jsx">
import { h } from 'vue'
import topBar from '../../components/topBar.vue'
import Footer from '../../components/Footer.vue'
import LeftMenu from '../../components/LeftMenu.vue'

import { useUserInfoStore } from '~/storeData/index'

const store = useUserInfoStore()

const menu = [
  {
    key: '/publisher/dashboard',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-home' }),
      ]),
    routeName: 'dashboard',
    title: '',
    label: 'Dashboard',
  },
  {
    key: '/publisher/products',
    label: 'Products Catalogue',
    icon: () => h('i', { class: 'iconfont icon-shangpin' }),
    routeName: 'products',
  },
  {
    key: '/publisher/brands',
    label: 'Brands Catalogue',
    routeName: 'brands',
    icon: () => h('i', { class: 'iconfont icon-pinpai' }),
  },
  {
    key: '/publisher/links',
    label: 'Product Links',
    routeName: 'links',
    icon: () => h('i', { class: 'iconfont icon-lianjie' }),
  },
  {
    key: '/publisher/reports',
    icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
    [ h('use', { 'xlink:href': '#icon-chart' })]),
    title: '',
    label: <div class="reports-beta">Reports <span>BETA</span></div>,
    // label: <div class="reports-beta">Reports <span>BETA</span></div>,
    // label: 'Reports',
    children: [
      {
        routeName: 'reports-performance',
        key: '/publisher/reports/performance',
        preKey: '/publisher/reports',
        label: 'Performance',
        title: 'Performance',
      },
      {
        preKey: '/publisher/reports',
        routeName: 'reports-product',
        key: '/publisher/reports/product',
        label: 'Product',
        title: 'Product',
      },
      {
        preKey: '/publisher/reports',
        routeName: 'reports-brand',
        key: '/publisher/reports/brand',
        label: 'Brand',
        title: 'Brand',
      },
    ]
  },
  {
    key: '/publisher/settings',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-a-shezhi2' }),
      ]),
    label: 'Settings',
    children: [
      {
        routeName: 'settings-profile',
        key: '/publisher/settings/profile',
        preKey: '/publisher/settings',
        label: 'Profile',
        title: 'Profile',
      },
      {
        preKey: '/publisher/settings',
        routeName: 'settings-open-api',
        key: '/publisher/settings/open-api',
        label: 'Open API',
        title: 'Open API',
      },
    ]
  },
  {
    key: '/publisher/finance',
    icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
    [ h('use', { 'xlink:href': '#icon-yinhangka' })]),
    title: '',
    label: <div class="reports-beta">Finance <span>BETA</span></div>,
    children: [
      {
        routeName: 'finance-payments',
        key: '/publisher/finance/payments',
        preKey: '/publisher/finance',
        label: 'Payments',
        title: 'Payments',
      },
    ]
  },
]
const bottomEntries = [
  {
    key: '/publisher/referral',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-zuanshi' }),
      ]),
    routeName: 'referral',
    title: 'Refer Your Friends',
    label: 'Refer Your Friends',
  },
  {
    key: 'contact-us',
    icon: () =>
      h('svg', { class: 'icon icon-contact-us', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-youjian-cu' }),
      ]),
    label: 'Contact Us',
  },
  {
    key: '/help',
    icon: () =>
      h('svg', { class: 'icon icon-help', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-wenhao' }),
      ]),
    routeName: 'help',
    label: 'Need Help?',
  },
]
</script>

<style lang="less" scoped>
.publisher {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  // min-width: 1200px;
  background: #f4f2f7;
  :deep(label) {
    font-weight: normal;
  }
  .cus-tag {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }
  :deep(.content-wrapper) {
    display: flex;
    border-radius: 8px;
    padding: 16px 16px 0;
    background-color: #fff;
    box-shadow: var(--section-box-shadow-6);
  }
  .right {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
  .content {
    padding: 0 16px;
    margin-top: 1px;
    overflow-y: auto;
    background: #f3f2f7;
    min-height: calc(100% - 48px);
  }
  .min-height {
    min-height: calc(100% - 73px);
  }
}
</style>